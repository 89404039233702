import { useContext, createContext } from 'react'
import { AnalyticsCampaign, useAnalytics } from './analytics.hooks'

type AnalyticsState = {
  segmentEnabled: boolean
  track: (event: string, properties?: any) => void
  page: (page: string, properties?: any) => void
  identify: (traits?: any) => void
  campaign?: AnalyticsCampaign
  entryPoint?: string
}

const analyticsInitialState: AnalyticsState = {
  segmentEnabled: false,
  track: () => {},
  page: () => {},
  identify: () => {}
}

const AnalyticsContext = createContext<AnalyticsState>(analyticsInitialState)

export const useAnalyticsContext = () => {
  const value = useContext(AnalyticsContext)

  if (!value) {
    throw new Error(
      'Must wrap component in AnalyticsContextProvider to use useAnalytics hook'
    )
  }

  return value
}

export const AnalyticsContextProvider = ({ children }) => {
  const { campaign, track, page, identify, entryPoint } = useAnalytics()
  let segmentEnabled = false

  if (typeof window !== 'undefined') {
    segmentEnabled = window.analytics !== undefined
  }

  return (
    <AnalyticsContext.Provider
      value={{
        segmentEnabled,
        track,
        page,
        identify,
        campaign,
        entryPoint
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}
