import { Form as FormikForm, Formik } from 'formik'
import * as yup from 'yup'

import FormikErrorFocus from './FormikHelpers'

export const getFormikProps = (fields) => {
  const initialValues = fields.reduce(
    (values, field) => ({
      ...values,
      [field.name]: field.initialValue
    }),
    {}
  )

  const validationSchema = yup.object().shape(
    fields.reduce(
      (validations, field) => ({
        ...validations,
        [field.name]: field.validation
      }),
      {}
    )
  )
  return { initialValues, validationSchema }
}

type FormProps = {
  children: JSX.Element
  className?: string
  fields: any
  onSubmit: (values: any, resetForm?) => void
  initialValues?: any
}

const Form = ({
  children,
  className,
  fields,
  onSubmit,
  ...otherProps
}: FormProps): JSX.Element => (
  <Formik
    {...getFormikProps(fields)}
    onSubmit={onSubmit}
    enableReinitialize
    validateOnBlur
    {...otherProps}
  >
    {() => (
      <FormikForm className={className} autoComplete="on">
        {children}
        <FormikErrorFocus />
      </FormikForm>
    )}
  </Formik>
)

export default Form
