export default {
  DARK_BLUE: { 1: '#242934', 2: '#0B1327' },
  GRAY: {
    1: '#F7FAFB',
    2: '#f9f6f5',
    3: '#FFFBE8',
    4: '#f2f3f7'
  },
  ERROR_RED: '#FE3D43',
  FLATIRONS_BLUE: '#214BA7',
  FLATIRONS_GOLD: '#D0A617',
  LIGHT_BLUE: { 1: '#DCE3FF', 2: '#0748EA', 3: '#8DC7FD', 4: '#216CBF' },
  LIGHT_GREY: '#F6F9FC',
  TEXT_BLUE: '#2C3956',
  MODERN_GOLD: {
    1: 'rgb(255, 205, 75)',
    2: '#FDC535',
    3: '#D0A617',
    4: '#FFCD4B',
    5: '#D0A618'
  },
  WHITE: '#FFFFFF',
  GREEN: '#3D7C5A',
  BLACK: '#000000'
} as const
