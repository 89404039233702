import Rollbar from 'rollbar'
import { wrapPage } from './root-wrapper'

// Fonts
import posthog from 'posthog-js'
import './src/styles/fonts.css'

const rollbar = new Rollbar({
  accessToken: process.env.GATSBY_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: process.env.NODE_ENV === 'production',
  environment: process.env.GATSBY_ACTIVE_ENV,
  codeVersion: process.env.GATSBY_CODEBUILD_RESOLVED_SOURCE_VERSION,
  payload: {
    environment: process.env.GATSBY_ACTIVE_ENV,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.GATSBY_CODEBUILD_RESOLVED_SOURCE_VERSION,
        guess_uncaught_frames: true
      }
    }
  }
})

export const onClientEntry = () => {
  window.rollbar = rollbar

  if (process.env.GATSBY_ENABLE_POSTHOG === 'true') {
    const apiHost = process.env.GATSBY_POSTHOG_HOST || ''
    const apiKey = process.env.GATSBY_POSTHOG_KEY || ''

    posthog.init(apiKey, {
      api_host: apiHost,
      loaded: (posthog) => {
        if (process.env.NODE_ENV === 'development') posthog.debug()
      }
    })
  }
}

export const wrapPageElement = wrapPage
