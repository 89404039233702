import queryString from 'query-string'

const STORAGE_KEY = 'analytics.campaign'

const getLocalStorage = () => {
  if (typeof window !== `undefined`) {
    return localStorage
  }
}

export const getExistingCampaign = () => {
  const storage = getLocalStorage()
  const existingCampaign = storage?.getItem(STORAGE_KEY)
  if (existingCampaign) {
    return JSON.parse(existingCampaign)
  }
  return undefined
}

export const setExistingCampaign = (campaign: { [key: string]: any }) => {
  const storage = getLocalStorage()
  storage?.setItem(STORAGE_KEY, JSON.stringify(campaign))
}

export const getCampaignData = (search: string) => {
  const campaign = queryString.parse(search) as {[key:string]: string};

  const {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_content,
    utm_term,
    utm_id
  } = campaign

  if (
    !utm_source &&
    !utm_medium &&
    !utm_campaign &&
    !utm_content &&
    !utm_term &&
    !utm_id
  ) {
    return undefined
  }

  return {
    id: utm_id,
    medium: utm_medium,
    name: utm_campaign,
    source: utm_source,
    term: utm_term,
    content: utm_content
  }
}
