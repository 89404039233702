import { WrapPageElementBrowserArgs } from 'gatsby'
import React from 'react'
import { ThemeProvider } from 'styled-components'
import Layout from './src/components/Layout'
import ContactDialogContextProvider from './src/context/ContactDialogContextProvider'
import { AnalyticsContextProvider } from './src/context/analytics/analytics.context'
import { GlobalStyle, theme } from './src/theme/global-style'

export const wrapPage = ({ element, props }: WrapPageElementBrowserArgs) => (
  <>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <AnalyticsContextProvider>
        <ContactDialogContextProvider>
          <Layout {...props}>{element}</Layout>
        </ContactDialogContextProvider>
      </AnalyticsContextProvider>
    </ThemeProvider>
  </>
)
