import { Field } from 'formik'
import styled from 'styled-components'
import { maxBreakpoints } from '../../../hooks/useMediaQueries'
import { Div, P } from '../../../styled/utils'
import colors from '../../../styles/colors'
import { H3 } from '../../work-page/workpage.styles'

export const NewsletterContainer = styled(Div)`
  backdrop-filter: blur(8px);
  background-color: ${`${colors.WHITE}08`};
  border-color: ${`${colors.WHITE}0D`};
  border-style: solid;
  border-width: 1px;
  display: flex;
  justify-content: space-between;
  padding: 32px 48px;

  @media ${maxBreakpoints.desktop} {
    flex-direction: column;
  }

  @media ${maxBreakpoints.mobile} {
    padding: 24px 32px;
  }
`

export const NewsletterMainInformation = styled(Div)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  max-width: 768px;
`

export const NewsletterSubscribe = styled(Div)``

export const SubscribeToNewsletterTitleText = styled(H3)`
  color: ${colors.WHITE};
  font-family: 'Montserrat';
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
`

export const SubscribeToNewsletterSubtitleText = styled(P)`
  color: ${`${colors.WHITE}B2`};
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
`

export const SubscribeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 24px;

  @media ${maxBreakpoints.desktop} {
    padding-left: 0;
  }

  @media ${maxBreakpoints.tablet} {
    padding-right: 0px;
  }
`

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  height: 96px;
  justify-content: center;
  position: relative;

  @media ${maxBreakpoints.mobile} {
    width: 100%;
  }
`

export const Label = styled.label`
  color: ${colors.WHITE};
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 24px;
`

export const InputWrapper = styled.div<{ hasError?: boolean }>`
  position: relative;
  width: 300px;

  @media ${maxBreakpoints.mobile} {
    width: 100%;
  }

  &::before {
    border-bottom: 1px solid
      ${({ hasError }) =>
        hasError ? colors.ERROR_RED : 'rgba(255, 255, 255, 0.5)'};
    bottom: 0;
    content: '';
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &::after {
    border-bottom: 2px solid
      ${({ hasError }) => (hasError ? colors.ERROR_RED : colors.MODERN_GOLD[1])};
    bottom: 0;
    content: '';
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }

  &:hover::before {
    border-bottom-color: ${({ hasError }) =>
      hasError ? colors.ERROR_RED : colors.WHITE};
  }

  &:focus-within::after {
    transform: scaleX(1);
  }
`

export const StyledInput = styled(Field)`
  background: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  border: none;
  color: ${colors.WHITE};
  font-size: 14px;
  outline: none;
  padding: 0px 32px 16px 0px;
  position: relative;
  width: 100%;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`

export const Button = styled.button`
  background-color: transparent;
  border: 2px solid ${colors.WHITE};
  color: ${colors.WHITE};
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  margin: 11px 0;
  padding: 10px 20px;
  text-transform: uppercase;
  transition: background-color 0.3s ease, color 0.3s ease;
  user-select: none;
  width: 188px;

  &:hover {
    background-color: ${colors.WHITE};
    color: ${colors.DARK_BLUE[1]};
  }

  &:disabled {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.5);
    color: rgba(255, 255, 255, 0.5);
    cursor: not-allowed;
    opacity: 0.85;
    pointer-events: none;
  }
`

export const ErrorText = styled.div`
  bottom: -12px;
  color: ${colors.ERROR_RED};
  font-size: 12px;
  margin-top: 5px;
  position: absolute;
`
