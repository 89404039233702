import { useMediaQuery } from '@mui/material'

export const sizes = {
  mobile_min_width: '320px',
  mobile_max_width: '599px',
  tablet_min_width: '600px',
  tablet_max_width: '1023px',
  desktop_min_width: '1024px',
  desktop_max_width: '1279px',
  large_desktop_min_width: '1280px',
  large_desktop_max_width: '1440px'
}

export const breakpoints = {
  mobile: `(max-width: ${sizes.mobile_max_width})`,
  tablet: `(max-width: ${sizes.tablet_max_width})`,
  desktop: `(min-width: ${sizes.desktop_min_width})`
}

export const maxBreakpoints = {
  mobile: `(max-width: ${sizes.mobile_max_width})`,
  tablet: `(max-width: ${sizes.tablet_max_width})`,
  desktop: `(max-width: ${sizes.desktop_max_width})`,
  largeDesktop: `(max-width: ${sizes.large_desktop_max_width})`
}

export const devices = {
  mobile: `(max-width: ${sizes.mobile_max_width})`,
  tablet: `(min-width: ${sizes.tablet_min_width}) and (max-width: ${sizes.tablet_max_width})`,
  desktop: `(min-width: ${sizes.desktop_min_width}) and (max-width: ${sizes.desktop_max_width})`,
  largeDesktop: `(min-width: ${sizes.large_desktop_min_width}) and (max-width: ${sizes.large_desktop_max_width})`
}

const useMediaQueries = () => {
  const isMobile = useMediaQuery(devices.mobile)
  const isTablet = useMediaQuery(devices.tablet)
  const isDesktop = useMediaQuery(devices.desktop)
  const isLargeDesktop = useMediaQuery(devices.largeDesktop)

  return { isMobile, isTablet, isDesktop, isLargeDesktop }
}

export default useMediaQueries
