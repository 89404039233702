import * as yup from 'yup'

export const yupRequired = yup
  .string()
  .min(4, 'Too short! At least 4 characters are required.')
  .nullable()
  .required('This is a required field.')

export const yupEmail = yup
  .string()
  .matches(
    /^(?!.*@.*@)(?!.*@.*-.*-)(?!.*@.*\.\..*\.)(?!.*@.*\..*\.\..*)(?!.*@[^a-zA-Z0-9-_.]).+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/,
    {
      message: 'Invalid email'
    }
  )
  .required('Email is required')

export const yupMaxChar = (maxChar: number) =>
  yup.string().max(maxChar, ({ value, max }) => `${value.length} / ${max}`)

export const yupRequiredObject = yup
  .object()
  .shape({
    id: yup.number().required(),
    label: yup.string().required(),
    value: yup.string().required()
  })
  .default(undefined)
  .required('Please select an option.')

export const yupRequiredArrayOfObjects = yup
  .array()
  .of(
    yup
      .object()
      .shape({ id: yup.number(), label: yup.string(), value: yup.string() })
      .nullable()
  )
  .required()
  .min(1, 'Please select at least one option.')
