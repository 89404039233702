import { Alert, AlertColor } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles(() => ({
  root: {
    padding: 22
  },
  message: {
    padding: 0
  },
  icon: {
    padding: 0,
    alignSelf: 'center',
    marginRight: 0,
    fontSize: '28px'
  },
  action: {
    padding: 0,
    marging: '0 auto'
  }
}))

type FWAlertProps = {
  children: React.ReactNode
  severity: AlertColor | undefined
  onClose: () => void
}

const FWAlert = ({ severity, onClose, children }: FWAlertProps) => {
  const styles = useStyles()

  return (
    <Alert severity={severity} onClose={onClose} classes={styles}>
      {children}
    </Alert>
  )
}

export default FWAlert
