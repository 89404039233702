import React from 'react'
import { TypographyOptions } from '@mui/material/styles/createTypography'
import { createTheme } from '@mui/system'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    headerSpecial: React.CSSProperties
    statement: React.CSSProperties
    legal: React.CSSProperties
    mainCTA: React.CSSProperties
    tertiaryCTA: React.CSSProperties
    tag: React.CSSProperties
    eyebrow: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    headerSpecial: React.CSSProperties
    statement?: React.CSSProperties
    legal?: React.CSSProperties
    mainCTA?: React.CSSProperties
    tertiaryCTA?: React.CSSProperties
    tag?: React.CSSProperties
    eyebrow?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    headerSpecial: true
    statement: true
    legal: true
    mainCTA: true
    tertiaryCTA: true
    eyebrow: true
    tag: true
  }
}

const theme = createTheme({})

const typography: TypographyOptions = {
  fontFamily: [
    'DM Sans',
    'DM Serif Display',
    'DM Serif Text',
    'Montserrat'
  ].join(','),

  statement: {
    fontFamily: "'DM Serif Display', 'Georgia', serif",
    fontWeight: 400,

    [theme.breakpoints.down('sm')]: {
      fontSize: '4rem',
      lineHeight: '4rem'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '4.75rem',
      lineHeight: '4.75rem'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      fontSize: '5.375rem',
      lineHeight: '5.375rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '6.25rem',
      lineHeight: '6.25rem'
    }
  },
  h1: {
    fontFamily: "'DM Serif Display', 'Georgia', serif",
    fontWeight: 400,

    [theme.breakpoints.down('sm')]: {
      fontSize: '3.125rem',
      lineHeight: '3.125rem'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '3.75rem',
      lineHeight: '3.75rem'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      fontSize: '4.375rem',
      lineHeight: '4.375rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '5rem',
      lineHeight: '5rem'
    }
  },
  headerSpecial: {
    fontFamily: "'DM Serif Display', 'Georgia', serif",
    fontWeight: 400,

    [theme.breakpoints.down('sm')]: {
      fontSize: '4rem',
      lineHeight: '4.75rem'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '4.75rem',
      lineHeight: '4.75rem'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      fontSize: '5.375rem',
      lineHeight: '5.375rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '6.25rem',
      lineHeight: '6.25rem'
    }
  },
  h2: {
    fontFamily: "'DM Serif Display', 'Georgia', serif",
    fontWeight: 400,

    [theme.breakpoints.down('sm')]: {
      fontSize: '2.25rem',
      lineHeight: '2.25rem'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '2.875rem',
      lineHeight: '2.875rem'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      fontSize: '3.5rem',
      lineHeight: '3.5rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '4.375rem',
      lineHeight: '4.375rem'
    }
  },
  h3: {
    fontFamily: "'DM Serif Display', 'Georgia', serif",

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem',
      lineHeight: '1.75rem'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '2.125rem',
      lineHeight: '2.125rem'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      fontSize: '2.25rem',
      lineHeight: '2.25rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '2.5rem',
      lineHeight: '2.5rem'
    }
  },
  h4: {
    fontFamily: "'Montserrat', 'Arial', sans-serif",
    fontWeight: 600,

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.375rem',
      lineHeight: '1.375rem'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1.375rem',
      lineHeight: '1.375rem'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      fontSize: '1.25rem',
      lineHeight: '1.875rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.5rem',
      lineHeight: '2.125rem'
    }
  },
  h5: {
    textTransform: 'uppercase',
    fontFamily: "'Montserrat', 'Arial', sans-serif",
    fontWeight: 700,

    [theme.breakpoints.down('lg')]: {
      fontSize: '0.875rem',
      lineHeight: '1rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.125rem',
      lineHeight: '1rem'
    }
  },
  subtitle1: {
    fontFamily: "'Montserrat', 'Arial', sans-serif",
    fontWeight: 400,

    [theme.breakpoints.down('md')]: {
      fontSize: '1.125rem',
      lineHeight: '1.75rem'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      fontSize: '1.25rem',
      lineHeight: '1.875rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.5rem',
      lineHeight: '2.125rem'
    }
  },
  body1: {
    fontFamily: "'Montserrat', 'Arial', sans-serif",
    fontWeight: 400,

    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
      lineHeight: '1.625rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.125rem',
      lineHeight: '1.75rem'
    }
  },
  body2: {
    fontFamily: "'Montserrat', 'Arial', sans-serif",
    fontWeight: 400,

    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem',
      lineHeight: '1.5rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
      lineHeight: '1.75rem'
    }
  },
  caption: {
    fontFamily: "'Montserrat', 'Arial', sans-serif",
    fontWeight: 400,
    textTransform: 'uppercase',

    [theme.breakpoints.up('xs')]: {
      fontSize: '0.75rem',
      lineHeight: '1.125rem'
    }
  },
  legal: {
    fontFamily: "'Montserrat', 'Arial', sans-serif",
    fontWeight: 400,

    [theme.breakpoints.up('xs')]: {
      fontSize: '0.75rem',
      lineHeight: '1.5rem'
    }
  },
  eyebrow: {
    fontFamily: "'Montserrat', 'Arial', sans-serif",
    fontWeight: 600,
    textTransform: 'uppercase',
    fontSize: '0.75rem',
    lineHeight: '1.125rem'
  },
  mainCTA: {
    fontFamily: "'Montserrat', 'Arial', sans-serif",
    fontWeight: 600,
    textTransform: 'uppercase',
    fontSize: '1rem',
    lineHeight: '1rem',
    whiteSpace: 'nowrap'
  },
  tertiaryCTA: {
    fontFamily: "'Montserrat', 'Arial', sans-serif",
    fontWeight: 500,
    textTransform: 'uppercase',
    fontSize: '1rem',
    lineHeight: '1.75rem'
  },
  tag: {
    fontFamily: "'Montserrat', 'Arial', sans-serif",
    fontWeight: 700,
    textTransform: 'uppercase',
    fontSize: '0.75rem',
    lineHeight: '1rem'
  }
}

export default typography
