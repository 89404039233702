import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { useEffect, useState } from 'react'

const variantMapping = {
  control: 'Contact',
  'schedule-a-call': 'Schedule a Call',
  'request-an-estimate': 'Request an Estimate',
  'lets-work-together': `Let's work together!`
}

const EXPERIMENT_ID = 'cta-copy'

export const usePostHogIntegration = (experimentId = EXPERIMENT_ID) => {
  const [ctaCopy, setCtaCopy] = useState('')

  const copyVariant = useFeatureFlagVariantKey(experimentId)

  useEffect(() => {
    if (typeof copyVariant === 'string') {
      setCtaCopy(variantMapping[copyVariant])
    }
  }, [copyVariant])

  return ctaCopy
}
