import FlatironsIcon from 'assets/flatirons-light-icon.svg'
import { InstagramLogo, LinkedinLogo, XLogo } from 'assets/social'
import styled from 'styled-components'
import colors from 'styles/colors'
import { Div, Img, P, Span } from '../../styled/utils'
import { heartSvg } from './svgs'
import { breakpoints } from '../../hooks/useMediaQueries'

const currentYear = new Date().getFullYear()

const Text = styled(P)`
  color: #f6f9fc;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  ${Span} {
    display: inline-block;
    transform: translateY(2px);
  }
`

const Wrapper = styled(Div)`
  @media ${breakpoints.tablet} {
    display: block;
  }
`

const Legal = () => {
  const socialMediaUI = (
    <Div spaceBetween w={158} mt={[0, 0, 40, 40]}>
      <a
        href="https://www.linkedin.com/company/flatirons-development/"
        target="blank"
      >
        <LinkedinLogo />
      </a>
      <a href="https://twitter.com/FlatironsDev" target="blank">
        <XLogo />
      </a>

      <a href="https://instagram.com/flatironsdevelopment" target="blank">
        <InstagramLogo />
      </a>
    </Div>
  )

  const copyrightUI = (
    <Div dflex>
      <Div alignCenter>
        <Img
          loading="lazy"
          src={FlatironsIcon}
          alt="flatirons logo icon"
          style={{ marginRight: 16, maxWidth: 40 }}
        />
      </Div>
      <Div alignCenter>
        <Text c={colors.WHITE} w={['100%', '100%', 300, 300]}>
          Copyright ©{currentYear} Flatirons Development, LLC. Made with
          <Span m="0 4px">{heartSvg}</Span>
          by the Flatirons Team
        </Text>
      </Div>
    </Div>
  )

  return (
    <Wrapper spaceBetween>
      {copyrightUI}
      {socialMediaUI}
    </Wrapper>
  )
}

export default Legal
