import { css } from 'styled'
import { getResponsivePropCss, getCssValue, getPropCss } from '.'

export const absolutePosition = {
  top: css`
    top: ${(p: any) => getCssValue(p.top)};
  `,
  bottom: css`
    bottom: ${(p: any) => getCssValue(p.bottom)};
  `,
  left: css`
    left: ${(p: any) => getCssValue(p.left)};
  `,
  right: css`
    right: ${(p: any) => getCssValue(p.right)};
  `
}

export const marginCss = {
  my: css`
    ${(p: any) => getPropCss('margin-top', p.my)};
    ${(p: any) => getPropCss('margin-bottom', p.my)};
  `,
  mt: css`
    ${(p: any) => getPropCss('margin-top', p.mt)};
  `,
  mb: css`
    ${(p: any) => getPropCss('margin-bottom', p.mb)};
  `,
  ml: css`
    ${(p: any) => getPropCss('margin-left', p.ml)};
  `,
  mr: css`
    ${(p: any) => getPropCss('margin-right', p.mr)};
  `,
  m: css`
    ${(p: any) => getPropCss('margin', p.m)};
  `
}

export const flexCss = {
  gap: css`
    ${(p: any) => getPropCss('gap', p.gap)};
  `
}

export const paddingCss = {
  py: css`
    ${(p: any) => getPropCss('padding-top', p.py)};
    ${(p: any) => getPropCss('padding-bottom', p.py)};
  `,
  px: css`
    ${(p: any) => getPropCss('padding-left', p.px)};
    ${(p: any) => getPropCss('padding-right', p.px)};
  `,
  pt: css`
    ${(p: any) => getPropCss('padding-top', p.pt)};
  `,
  pb: css`
    ${(p: any) => getPropCss('padding-bottom', p.pb)};
  `,
  pl: css`
    ${(p: any) => getPropCss('padding-left', p.pl)};
  `,
  pr: css`
    ${(p: any) => getPropCss('padding-right', p.pr)};
  `,
  p: css`
    ${(p: any) => getPropCss('padding', p.p)};
  `
}
