export const fontSize = {
  xs: '0.75rem',
  sm: '0.875rem',
  base: '1rem',
  lg: '1.125rem',
  xl: '1.313rem',
  xl2: '1.438rem',
  xl3: '1.875rem',
  xl4: '1.938rem',
  xl5: '3rem',
  xl6: '4.25rem'
}
