import { sizes } from 'hooks/useMediaQueries'
import { css } from '..'
import { animations } from './animations'
import { colors } from './colorPalette'
import { debugCss } from './debugCss'
import { dimensionsCss } from './dimensionsCss'
import { fontCss } from './fontCss'
import { fontSize } from './fontSize'
import { globalCss } from './globalCss'
import { layoutCss } from './layoutCss'
import { mediaQueries } from './mediaQueries'
import { absolutePosition, flexCss, marginCss, paddingCss } from './positionCss'
import { textCss } from './textCss'
import { textStyles } from './textStyles'

export const getCssValue = (v) => v + (typeof v === 'number' ? 'px' : '')
export const getResponsivePropCss = ({ prop, values }) => {
  return css`
    ${values[0] && `${prop}: ${values[0]}`};
    @media (max-width: ${sizes.desktop_max_width}) {
      ${values[1] ? `${prop}: ${values[1]}` : ''};
    }
    @media (max-width: ${sizes.tablet_max_width}) {
      ${values[2] ? `${prop}: ${values[2]}` : ''};
    }
    @media (max-width: ${sizes.mobile_max_width}) {
      ${values[3] ? `${prop}: ${values[3]}` : ''};
    }
  `
}
export const getPropCss = (prop, values) => {
  if (Array.isArray(values))
    return getResponsivePropCss({
      prop,
      values: values.map((i) => getCssValue(i))
    })
  return css`
    ${`${prop}: ${getCssValue(values)}`};
  `
}

export const appCss = {
  ...animations,
  ...dimensionsCss,
  ...layoutCss,
  ...textCss,
  ...textStyles,
  ...fontCss,
  ...debugCss,
  ...globalCss,
  ...absolutePosition,
  ...marginCss,
  ...paddingCss,
  ...flexCss
}

export const theme = {
  colors,
  textStyles,
  fontSize,
  css: appCss,
  media: mediaQueries
} as const

export type Theme = typeof theme

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}

export default theme
