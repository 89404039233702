import { createTheme, PaletteOptions } from '@mui/material'
import colors from 'styles/colors'

import components from './components'
import typography from './typography'

const paletteSettings: PaletteOptions = {
  primary: {
    main: colors.MODERN_GOLD[1]
  },
  secondary: {
    main: colors.LIGHT_BLUE[2]
  },
  error: {
    main: colors.ERROR_RED
  }
}

const typographyTheme = createTheme({ typography })

const theme = createTheme({
  typography: typography,
  palette: { ...paletteSettings },
  components: components(typographyTheme)
})

export default theme
