import { ErrorMessage, useFormikContext } from 'formik'
import { useNewsletterSubmit } from '../../../hooks/useNewsletter'
import Form from '../../Form'
import useFormikField from '../../Form/useFormikField'
import { yupEmail } from '../../Form/validations'
import * as S from './Styles'

interface InputFieldProps {
  label: string
  name: string
  placeholder: string
  type: string
}

interface SubmitButtonProps {
  isSubmitting: boolean
  submittedRecently: boolean
}

const InputField = ({ name, label, placeholder, type }: InputFieldProps) => {
  const { hasError: hasFormikError } = useFormikField(name)

  return (
    <S.InputGroup>
      <S.Label htmlFor={name}>{label}</S.Label>

      <S.InputWrapper hasError={hasFormikError}>
        <S.StyledInput
          id={name}
          type={type}
          name={name}
          placeholder={placeholder}
        />
      </S.InputWrapper>

      <ErrorMessage name={name} component={S.ErrorText} />
    </S.InputGroup>
  )
}

const SubmitButton = ({
  isSubmitting,
  submittedRecently
}: SubmitButtonProps) => {
  const { errors } = useFormikContext()

  const hasErrors = Object.keys(errors).length > 0

  return (
    <S.Button
      type="submit"
      disabled={hasErrors || isSubmitting || submittedRecently}
    >
      Subscribe
    </S.Button>
  )
}

export const Newsletter = () => {
  const { handleNewsletterSubmit, isSubmitting, submittedRecently } =
    useNewsletterSubmit()

  const newsletterField = [
    {
      label: 'E-mail',
      name: 'email',
      placeholder: 'example@email.com',
      type: 'text',
      validation: yupEmail
    }
  ]

  return (
    <S.NewsletterContainer mt={72} mb={72}>
      <S.NewsletterMainInformation>
        <S.SubscribeToNewsletterTitleText>
          Subscribe to our newsletter
        </S.SubscribeToNewsletterTitleText>

        <S.SubscribeToNewsletterSubtitleText>
          Hard-curated insights on AI and software development.
        </S.SubscribeToNewsletterSubtitleText>
      </S.NewsletterMainInformation>

      <S.NewsletterSubscribe>
        <Form fields={newsletterField} onSubmit={handleNewsletterSubmit}>
          <S.SubscribeContainer>
            <InputField {...newsletterField[0]} />

            <SubmitButton
              isSubmitting={isSubmitting}
              submittedRecently={submittedRecently}
            />
          </S.SubscribeContainer>
        </Form>
      </S.NewsletterSubscribe>
    </S.NewsletterContainer>
  )
}
