import { Theme } from '@mui/material'
import colors from 'styles/colors'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    secondaryOutlined: true
  }
}

const components = (theme: Theme): Theme['components'] => ({
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true
    }
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 0,
        textTransform: 'uppercase',
        color: '#242934',
        fontFamily: 'Montserrat',
        fontWeight: 600,
        borderRadius: 0,
        [theme.breakpoints.down('md')]: {
          fontSize: '1rem',
          lineHeight: '1.25rem'
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '1.25rem',
          lineHeight: '1.5rem'
        }
      }
    },
    variants: [
      {
        props: { variant: 'contained' },
        style: {
          padding: '24px 48px',
          boxShadow: 'none',
          transition: 'backgorund-color 3s linear',

          '&:hover': {
            background:
              'linear-gradient(to left bottom, transparent 50%, rgba(0,0,0,.4) 0) no-repeat 100% 0/1.6em 1.6em,linear-gradient(-135deg, transparent 1.15em, #FFCD4B 0)',
            boxShadow: 'none'
          },

          '&:active': {
            background:
              'linear-gradient(to left bottom, transparent 50%, rgba(0,0,0,.4) 0) no-repeat 100% 0/1.6em 1.6em,linear-gradient(-135deg, transparent 1.15em, #D0A617 0)',
            boxShadow: 'none'
          }
        }
      },
      {
        props: { variant: 'secondaryOutlined' },
        style: {
          padding: '24px 48px',
          boxShadow: 'none',
          transition: 'backgorund-color 3s linear',
          color: colors.LIGHT_BLUE[2],
          border: `2px solid ${colors.LIGHT_BLUE[2]}`,

          '&:hover': {
            backgroundColor: colors.LIGHT_GREY,
            boxShadow: 'none',
            border: `2px solid ${colors.LIGHT_BLUE[2]}`
          },
          '&:active': {
            backgroundColor: 'rgba(7, 72, 234, 0.2)',
            boxShadow: 'none',
            border: `2px solid ${colors.LIGHT_BLUE[2]}`
          }
        }
      }
    ]
  },
  MuiInput: {
    styleOverrides: {
      root: {
        padding: '17px 0 11px 0',
        fontSize: `1rem !important`,
        fontWeight: 500,
        color: `${colors.DARK_BLUE[1]} !important`,

        '&.MuiAutocomplete-inputRoot': {
          padding: '17px 0 11px 0'
        }
      }
    }
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        transform: 'none',
        fontWeight: 600,
        color: colors.DARK_BLUE[1],

        '&.Mui-focused': {
          color: colors.DARK_BLUE[1]
        },

        '&.Mui-error': {
          color: colors.DARK_BLUE[1]
        },

        [theme.breakpoints.up('xs')]: {
          fontSize: '0.875rem',
          lineHeight: '1rem'
        }
      }
    }
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontSize: '0.75rem',
        textTransform: 'none',
        margin: '16px 0 0 0'
      }
    }
  },
  MuiLink: {
    styleOverrides: {
      root: {
        color: colors.LIGHT_GREY,
        '&:active': {
          color: `${colors.DARK_BLUE[1]}`
        },
        '&:hover': {
          color: `${colors.FLATIRONS_BLUE}`
        }
      }
    }
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        color: 'inherit'
      }
    }
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        backgroundColor: `${colors.MODERN_GOLD[3]}`,
        height: '4px'
      }
    }
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
        fontWeight: 400,
        color: `${colors.DARK_BLUE[1]}`,
        borderBottom: '1px solid',

        [theme.breakpoints.down('md')]: {
          fontSize: '1rem',
          lineHeight: '1.625rem'
        },

        '&.Mui-selected': {
          fontWeight: 700,
          color: `${colors.DARK_BLUE[1]}`
        }
      }
    }
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        color: 'unset'
      }
    }
  },
  MuiAccordionSummary: {
    styleOverrides: {
      content: {
        margin: '32px 0'
      }
    }
  }
})

export default components
