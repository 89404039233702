import { css } from 'styled'

export const textStyles = {
  h1: css`
    font-size: 38px;
    line-height: 52px;
    font-weight: normal;
    font-family: 'DM Serif Display', 'Times New Roman', Times, serif;
  `,
  h2: css`
    font-size: 32px;
    line-height: 36px;
    font-weight: 600;
  `,
  h3: css`
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  `,
  h4: css`
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  `,
  h5: css`
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  `,
  body1: css`
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
  `,
  body2: css`
    font-size: 14px;
    line-height: 22px;
    font-weight: normal;
  `,
  body3: css`
    font-size: 35px;
    line-height: 36px;
    font-weight: normal;
    font-family: Montserrat;
  `,
  label1: css`
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  `,
  label2: css`
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
  `,
  label3: css`
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: 1.42px;
  `,
  helperText: css`
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  `,
  buttonText: css`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  `,
  link: css`
    user-select: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    :hover {
      cursor: pointer;
      text-decoration-line: underline;
      opacity: 0.8;
    }
  `
}
