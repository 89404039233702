const gray = {
  gray10: '#FCFCFD',
  gray50: '#F8F8F9',
  gray100: '#F0F0F1',
  gray200: '#E4E4E5',
  gray300: '#CACACB',
  gray400: '#B1B1B2',
  gray500: '#989898',
  gray600: '#7F7F7F',
  gray700: '#656566',
  gray800: '#4C4C4C',
  gray900: '#333333',
  gray1000: '#1C1C1C'
}

const blue = {
  blue50: '#F9FAFD',
  blue100: '#F1F4FB',
  blue200: '#E6EBF7',
  blue300: '#C2CEEC',
  blue400: '#9FB2E2',
  blue500: '#7C97D7',
  blue600: '#597ACD',
  blue700: '#214BA7',
  blue800: '#253D78',
  blue900: '#1A2A55',
  blue1000: '#0F1A31'
}

const red = {
  red100: '#FCEDEA',
  red200: '#F8DCD4',
  red300: '#F2B9AA',
  red400: '#EB967F',
  red500: '#E57354',
  error: '#CF4520',
  red600: '#CF4520',
  red700: '#A9381A',
  red800: '#842C14',
  red900: '#5E1F0F',
  red1000: '#381309'
}

const green = {
  green50: '#EDFBEF',
  green100: '#C3F2CC',
  green200: '#9DEAAB',
  green300: '#62DD79',
  green400: '#2AC046',
  green500: '#25AA3E',
  green600: '#209135',
  green700: '#1A782C',
  green800: '#155F23',
  green900: '#0F4519',
  green1000: '#0A2C10'
}

const orange = {
  orange50: '#FEF9EE',
  orange100: '##FDEAC7',
  orange200: '#FBDCA0',
  orange300: '#FACD78',
  orange400: '#F8BE51',
  orange500: '#EF9F09',
  orange600: '#CD8808',
  orange700: '#AA7106',
  orange800: '#885A05',
  orange900: '#654304',
  orange1000: '#432D03'
}

const fuse = {
  fuseBg1: '#FAF8F6',
  fuseBg2: '#F3F2F1',
  fuseRed: '#FD395A',
  turquoiseBlue: '#68EBEB',
}

export const colorPalette = {
  ...orange,
  ...green,
  ...gray,
  ...blue,
  ...red,
  ...fuse,
  red: 'red',
  white: 'white',
  black: 'black',
  gold: '#d0a617',
  greenWater: '#71FCE4',
  primary: '#2A52A9',
  light: '#f7fafc',
  menu: 'white',
  darkGray: '#50545d',
  dark: '#242934'
}

export const colorLabels = {
  error: colorPalette.red600,
  text: colorPalette.gray1000,
  textPrim: colorPalette.gray1000,
  textSec: colorPalette.gray800,
  textTert: colorPalette.gray700,
  textLink: colorPalette.blue700,
  placeholder: colorPalette.gray600,
  disabled1: colorPalette.gray50,
  disabled2: colorPalette.gray300,
  disabled3: colorPalette.gray400,
  background: colorPalette.gray10
}

export const colors = { ...colorPalette, ...colorLabels }
