import { css } from '..'
import { getCssValue, getPropCss } from '.'

export const dimensionsCss = {
  w100: css`
    width: 100%;
  `,
  h100: css`
    height: 100%;
  `,
  w: css`
    ${(p: any) => getPropCss('width', p.w)};
  `,
  h: css`
    ${(p: any) => getPropCss('height', p.h)};
  `,
  dim: css`
    height: ${(p: any) => getCssValue(p.dim)};
    width: ${(p: any) => getCssValue(p.dim)};
  `,
  maxw: css`
    ${(p: any) => getPropCss('max-width', p.maxw)};
  `,
  maxh: css`
    ${(p: any) => getPropCss('max-height', p.maxh)};
  `,
  minw: css`
    /* minimum width in px */
    min-width: ${(p: any) => p.minw}px;
  `,
  minh: css`
    ${(p: any) => getPropCss('min-height', p.minh)};
  `,
  br: css`
    /* border radius in px */
    border-radius: ${(p: any) => p.br}px;
  `
}
