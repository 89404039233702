import { devices } from 'hooks/useMediaQueries'
import styled, { css } from 'styled-components'
import { Div } from 'styled/utils'

export const verticalCenteredFlex = css`
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const HorizontalPaddingsWrapper = styled(Div)`
  padding: 0 80px;

  @media ${devices.desktop} {
    padding: 0 64px;
  }
  @media ${devices.tablet} {
    padding: 0 40px;
  }
  @media ${devices.mobile} {
    padding: 0 16px;
  }
`

export const HideScrollBar = css`
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

export const ContentSection = styled.div`
  padding: 80px 80px 120px 80px;
  display: flex;
  justify-content: center;

  @media ${devices.desktop} {
    padding: 80px 64px 96px 64px;
  }

  @media ${devices.tablet} {
    justify-content: flex-start;
    padding: 64px 40px 96px 40px;
  }

  @media ${devices.mobile} {
    padding: 64px 16px 80px 16px;
  }
`

export const Container = styled.div`
  margin: 0 auto;
  padding: 120px 80px 120px 80px;
  max-width: 950px;

  @media ${devices.desktop} {
    padding: 80px 64px 96px 64px;
  }

  @media ${devices.tablet} {
    justify-content: flex-start;
    padding: 96px 40px 96px 40px;
  }

  @media ${devices.mobile} {
    padding: 64px 16px 80px 16px;
  }
`

export const GeneralWrapper = styled(Div)`
  width: 100%;
  max-width: 1280px;

  @media ${devices.largeDesktop} {
    max-width: inherit;
    padding: 0 80px;
  }

  @media ${devices.desktop} {
    max-width: inherit;
    padding: 0 80px;
  }

  @media ${devices.tablet} {
    max-width: inherit;
    padding: 0 40px;
  }

  @media ${devices.mobile} {
    max-width: inherit;
    padding: 0 16px;
  }
`
