import { Box, Typography } from '@mui/material'

type ErrorProps = {
  code: string
  message: string
}

export const FormError = ({ code, message }: ErrorProps) => (
  <Box sx={{ display: 'flex', gap: '4px', margin: '0 22px' }}>
    <Typography variant="body1" color="error">
      <b>{code}</b>
    </Typography>
    <Typography variant="body1" color="error">
      {message}
    </Typography>
  </Box>
)
