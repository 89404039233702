import { useContext, useEffect, useState, createContext } from 'react'

type State = {
  dialogOpen: boolean
  setDialogOpen: (open: boolean) => void
}

const ContactDialogContext = createContext<State>({} as State)

const ContactDialogContextProvider = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState(false)

  useEffect(() => {
    if (dialogOpen) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }
  }, [dialogOpen])

  const value = { dialogOpen, setDialogOpen }

  return (
    <ContactDialogContext.Provider value={value}>
      {children}
    </ContactDialogContext.Provider>
  )
}

export default ContactDialogContextProvider

export const useContactDialogContext = () => {
  const context = useContext(ContactDialogContext)
  if (typeof context === 'undefined') {
    throw new Error('useSession must be used within a SessionContext')
  }
  return context
}
