import { Box, Typography } from '@mui/material'
import { addToast } from 'components/common/FWToast'
import { FormError } from 'components/common/FormError'
import { useCallback, useState } from 'react'
import { AnalyticsEvent } from '../constants/analytics/events'
import { useAnalyticsContext } from '../context/analytics/analytics.context'
import colors from '../styles/colors'

interface NewsletterSubmitData {
  email: string
}

const successUI = (
  <Box sx={{ margin: '0 22px' }}>
    <Typography variant="body1" color={colors.GREEN}>
      <b>Thank you for subscribing to our newsletter!</b>
    </Typography>
  </Box>
)

export const useNewsletterSubmit = () => {
  const { track } = useAnalyticsContext()
  const [submittedRecently, setSubmittedRecently] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleNewsletterSubmit = useCallback(
    async (values: NewsletterSubmitData) => {
      try {
        if (submittedRecently) return
        setSubmittedRecently(true)

        setIsSubmitting(true)

        const isBlogPage =
          typeof window !== 'undefined'
            ? location.pathname.startsWith('/blog/')
            : false

        const payload = {
          email: values.email,
          source: isBlogPage ? 'blog_post' : 'footer'
        }

        track(AnalyticsEvent.NEWSLETTER_SUBMIT, payload)
        addToast(successUI, 'success')

        setTimeout(() => {
          setSubmittedRecently(false)
        }, 2000)
      } catch (error) {
        addToast(
          <FormError
            code=""
            message="Something went wrong. Please try again later."
          />,
          'error'
        )
      } finally {
        setSubmittedRecently(false)
        setIsSubmitting(false)
      }
    },
    [submittedRecently]
  )

  return { handleNewsletterSubmit, isSubmitting, submittedRecently }
}
