import { Typography as MUITypography } from '@mui/material'
import { ContentSection } from 'components/common/styledUtils'
import { Link } from 'gatsby'
import { breakpoints, devices, sizes } from 'hooks/useMediaQueries'
import styled, { css } from 'styled-components'
import colors from 'styles/colors'
import { verticalCenteredFlex } from './../common/styledUtils'

const HEADER_HEIGHT = 88

const Typography = styled(MUITypography)<{ $lightYellow?: boolean }>`
  & > span {
    color: ${({ $lightYellow }) =>
      $lightYellow ? colors.MODERN_GOLD[4] : colors.LIGHT_BLUE[4]};
  }
`

export const Container = styled.div<any>`
  background-color: ${({ isWhiteVariant }) =>
    isWhiteVariant ? 'white' : colors.DARK_BLUE[2]};

  color: ${({ isWhiteVariant }) =>
    isWhiteVariant ? colors.DARK_BLUE[1] : 'white'};
  padding-top: ${HEADER_HEIGHT}px;
`

export const GrayContainer = styled.div`
  background-color: ${colors.LIGHT_GREY};
`

export const WhiteContainer = styled.div`
  background-color: ${colors.WHITE};
`

export const Wrapper = styled.main`
  max-width: 1440px;
  margin: 0 auto;
`

export const HeroSection = styled(ContentSection)`
  position: relative;
  height: 1013px;

  @media ${devices.desktop} {
    height: 890px;
  }

  @media ${devices.tablet} {
    height: 825px;
  }

  @media ${devices.mobile} {
    height: auto;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
  }
`

export const HeroRightImageWrapper = styled.div`
  position: absolute;

  height: auto;
  width: 65%;
  left: 299px;
  top: 243px;
  margin: 80px;
  display: none;

  @media (min-width: ${sizes.tablet_min_width}) {
    display: block;
  }

  @media ${devices.desktop} {
    width: 70%;
    height: auto;
    left: 217px;
    top: 259px;
    margin: 80px;
  }

  @media ${devices.tablet} {
    width: 70%;
    max-width: 629px;
    height: auto;
    left: 10%;
    top: 40%;
    margin: 64px;
  }
`

export const HeroMobileImageWrapper = styled.div`
  margin-top: 42px;
  display: none;

  @media ${devices.mobile} {
    display: block;
  }
`

export const SingleImageWrapper = styled.div`
  position: absolute;
  height: 486px;
  top: 260px;

  @media (max-width: ${sizes.tablet_min_width}) {
    display: none;
  }
`

export const HeroLeftImageWrapper = styled.div`
  position: absolute;
  height: 486px;
  width: 730px;
  left: 80px;
  top: 519px;
  display: none;

  @media (min-width: ${sizes.tablet_min_width}) {
    display: block;
  }

  @media ${devices.desktop} {
    width: 50%;
    height: auto;
    left: 64px;
    top: 548px;
  }

  @media ${devices.tablet} {
    width: 491px;
    height: auto;

    left: 40px;
  }

  @media ${devices.mobile} {
    width: 300px;
    height: auto;
    left: 16px;
    top: 70%;
  }
`

export const Separator = styled.div<{ $lightYellow?: boolean }>`
  width: 40px;
  height: 0px;
  border: 2px solid
    ${({ $lightYellow }) =>
      $lightYellow ? colors.MODERN_GOLD[4] : colors.MODERN_GOLD[3]};
`

export const HeaderTextSection = styled.section`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 24px;
  left: 80px;
  z-index: 1;

  max-width: 860px;

  @media ${devices.desktop} {
    left: 64px;
  }

  @media ${devices.tablet} {
    left: 40px;
    max-width: 451px;
  }

  @media ${devices.mobile} {
    left: 16px;
    max-width: unset;
    position: unset;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const HeroTitle = styled(Typography).attrs({
  variant: 'headerSpecial'
})`
  @media ${devices.mobile} {
    font-size: 12vw !important;
    word-break: break-word;
    line-height: 12vw !important;
  }
`

export const HeroSubtitle = styled(Typography).attrs({
  variant: 'h4',
  component: 'h1'
})`
  text-transform: uppercase;
`

export const SubtitleEyeBrown = styled(Typography)`
  text-transform: uppercase;
`

export const TagsWrapper = styled.div`
  display: flex;
  gap: 32px;
`
export const Tag = styled(Typography).attrs({ variant: 'tag' })``

export const GoalSection = styled(ContentSection)`
  display: flex;
  justify-content: flex-end;
  gap: 32px;

  margin: 0 auto;

  @media ${devices.mobile} {
    gap: 24px;
  }
`

export const GoalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 840px;

  @media ${devices.desktop} {
    max-width: 602px;
  }

  @media ${devices.tablet} {
    max-width: 569px;
  }

  @media ${devices.mobile} {
    max-width: unset;
    gap: 24px;
  }
`

export const H2 = styled(Typography).attrs({ variant: 'h2' })``
export const H3 = styled(Typography).attrs({ variant: 'h3' })``
export const H4 = styled(Typography).attrs({ variant: 'h4' })``

export const GoalTitle = styled(H2)<{ $lightYellow?: boolean }>`
  color: ${({ $lightYellow }) =>
    $lightYellow ? colors.MODERN_GOLD[4] : colors.MODERN_GOLD[3]};
`

export const BodyText = styled(Typography).attrs({
  component: 'div',
  variant: 'body1'
})``

export const WhatWeDidSection = styled(ContentSection)<{
  $isWhiteVariant?: boolean
}>`
  display: flex;
  flex-direction: column;
  gap: 80px;
  margin: 0 auto;
  padding-top: ${({ $isWhiteVariant }) => ($isWhiteVariant ? '120px' : 0)};
  ${({ $isWhiteVariant }) =>
    $isWhiteVariant &&
    css`
      color: black;
    `};

  @media ${devices.desktop} {
    gap: 64px;
  }

  @media ${breakpoints.tablet} {
    gap: 48px;
  }
`

export const ServicesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 29px;
`

export const Services = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 40px;
  row-gap: 80px;

  @media ${breakpoints.desktop} {
    column-gap: 24px;
    row-gap: 64px;
  }

  @media ${devices.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${devices.mobile} {
    grid-template-columns: 1fr;
  }
`

export const ServicesWithTwoColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 400px);
  column-gap: 40px;
  row-gap: 80px;

  @media ${breakpoints.desktop} {
    column-gap: 24px;
    row-gap: 64px;
  }

  @media ${devices.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${devices.mobile} {
    grid-template-columns: 1fr;
  }
`

export const ServiceText = styled(Typography).attrs({ variant: 'h4' })``

export const Icon = styled.img`
  height: 50px;
  width: 50px;
`

export const CoverImage = styled.div`
  height: 100%;
  width: 100%;

  min-width: 1440px;
  min-height: 810px;

  background: #ffd6dc;
`

export const FullBleedImageWrapper = styled.div`
  max-width: 1060px;
  margin: 0 auto;
  padding: 0;

  @media ${breakpoints.tablet} {
    padding: 0 40px 80px 40px;
  }

  @media ${devices.mobile} {
    padding: 0 16px 80px 16px;
  }
`

export const TextContainer = styled.div<{ $isWhiteVariant?: boolean }>`
  background-color: ${({ $isWhiteVariant }) =>
    $isWhiteVariant ? 'white' : colors.LIGHT_GREY};
`

const WithWrapperPaddings = styled.div`
  padding: 120px 80px;
  display: flex;
  gap: 32px;

  @media ${devices.desktop} {
    padding: 96px 40px 64px;
  }

  @media ${breakpoints.tablet} {
    padding: 120px 0px;
    gap: 24px;
  }

  @media ${devices.mobile} {
    padding: 80px 16px 48px;
  }
`

export const TextWrapperStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  max-width: 840px;
  margin: 0 auto;
  color: ${colors.DARK_BLUE[1]};
  width: 100%;

  @media ${devices.desktop} {
    max-width: 590px;
  }

  @media ${breakpoints.tablet} {
    max-width: 570px;
    gap: 24px;
  }
`

export const TextWrapperWithoutWrapper = styled.div`
  ${TextWrapperStyles}
`

export const TextWrapperForImageBlock = styled(WithWrapperPaddings)`
  ${TextWrapperStyles};

  padding: 120px 0 80px;
  display: flex;
  gap: 32px;

  @media ${breakpoints.desktop} {
    padding: 96px 0px 64px;
  }

  @media ${breakpoints.tablet} {
    padding: 96px 0px 64px;
    gap: 24px;
  }

  @media ${devices.mobile} {
    padding: 80px 16px 48px;
  }
`

export const BodyTextWrapper = styled.div`
  ${verticalCenteredFlex};
  align-items: flex-start;

  gap: 16px;
  margin-top: 48px;
`

export const Blocks = styled.div`
  ${verticalCenteredFlex};

  @media ${breakpoints.desktop} {
    gap: 80px;
  }
`

export const ImageBlockWithoutWrapper = styled.div`
  max-width: 1060px;
  margin: 0 auto;
`

export const ImageBlock = styled(ImageBlockWithoutWrapper)<{
  $fullScreenImage?: boolean
}>`
  ${({ $fullScreenImage }) =>
    $fullScreenImage &&
    css`
      max-width: initial;
    `};

  @media ${devices.desktop} {
    margin: 0 40px 0 40px;
  }

  @media ${devices.tablet} {
    margin: 0 40px 48px 40px;
  }

  @media ${devices.mobile} {
    margin: 0 16px 48px 16px;
  }
`

export const ImageBlockWithBottomMargin = styled(ImageBlock)`
  margin: 0 auto 120px auto;

  @media ${devices.desktop} {
    margin: 0 40px 96px 40px;
  }

  @media ${devices.mobile} {
    margin: 0 16px 80px 16px;
  }
`

export const Caption = styled(Typography).attrs({
  component: 'div',
  variant: 'body1'
})`
  padding-top: 64px;

  @media ${devices.desktop} {
    padding-top: 48px;
  }

  @media ${breakpoints.tablet} {
    padding-top: 24px;
  }
`

export const TextWithImageContainer = styled.div<{ $backgroundColor?: string }>`
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor ? $backgroundColor : colors.LIGHT_GREY};
  color: ${colors.DARK_BLUE[1]};
  padding-bottom: 120px;

  @media ${breakpoints.tablet} {
    padding-bottom: 0;
  }
`

export const AnimatedImagesContainer = styled.div`
  background-color: #ffd6dc;
  height: 810px;
  width: 100%;
`

export const AnimatedImage = styled.div`
  @keyframes scroll {
    to {
      background-position: 0 -810px;
    }
  }

  width: 50%;
  height: 100%;
  background: url(http://github.com/pauloluan.webp) repeat;
  animation: scroll 2s linear infinite;

  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
`

export const HalfColoredContainer = styled.div<{ $color?: string }>`
  background: linear-gradient(
      to bottom,
      ${colors.WHITE} 57%,
      ${({ $color }) => ($color ? $color : colors.GRAY[2])} 0%
    )
    no-repeat bottom;

  background-size: 100% 100%;
`

export const DesignSystemImage = styled.img`
  max-width: 1440px;

  @media (max-width: 1440px) {
    width: 100vw;
    height: auto;
  }
`

export const ProjectsContainer = styled(WithWrapperPaddings)`
  max-width: 1440px;
  margin: 0 auto;

  color: ${colors.DARK_BLUE[1]};

  display: flex;
  ${verticalCenteredFlex};
  align-items: flex-start;
`

export const ProjectsWrapper = styled.div`
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: 64px;

  @media ${devices.tablet} {
    padding: 0 40px;
  }
`

export const ProjectsTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const ProjectsBlocksWrapper = styled.section`
  display: flex;
  gap: 40px;

  @media ${devices.desktop} {
    gap: 24px;
  }

  @media ${devices.tablet} {
    flex: 1;
    flex-direction: column;
    gap: 32px;
  }

  @media ${devices.mobile} {
    flex-direction: column;
    gap: 48px;
  }
`

export const Testimonial = styled(WithWrapperPaddings)<{
  $backgroundColor?: string
}>`
  background-color: ${({ $backgroundColor }) => $backgroundColor || 'white'};

  @media ${devices.mobile} {
    padding: 80px 16px 80px;
  }
`

export const TagLink = styled(Link)`
  color: ${colors.BLACK};

  &:hover {
    opacity: 0.8;
  }
`

export const WrapperImageAndText = styled.div`
  background-color: #f6f9fc;
`

export const NegativeMargin = styled.div`
  margin: 0 -40px 0 -40px;

  @media ${devices.mobile} {
    margin: 0;
  }
`
