import { ThemeProvider as MUIThemeProvider } from '@mui/material'
import Footer from 'components/Footer'
import { useContactDialogContext } from 'context/ContactDialogContextProvider'
import { PageProps } from 'gatsby'
import muiTheme from 'gatsby-theme-material-ui-top-layout/theme'
import React, { Suspense, lazy } from 'react'
import { ThemeProvider } from 'styled-components'
import { theme } from 'styled/theme'
import FWToast from './common/FWToast'
const ContactDialog = lazy(() => import('components/common/ContactDialog'))

type Props = {
  children: React.ReactNode
} & Omit<PageProps, 'children'>
function Layout({ children, path }: Props) {
  const { dialogOpen, setDialogOpen } = useContactDialogContext()

  return (
    <ThemeProvider theme={theme}>
      <FWToast />
      <MUIThemeProvider theme={muiTheme}>
        {children}
        {dialogOpen && (
          <Suspense>
            <ContactDialog onClose={() => setDialogOpen(false)} />
          </Suspense>
        )}
        <Footer />
      </MUIThemeProvider>
    </ThemeProvider>
  )
}

export default Layout
