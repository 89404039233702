import { createGlobalStyle } from 'styled-components'

export const theme = {
  fontFamily: {
    heading: 'DM Serif Text',
    main: 'Montserrat'
  },
  fontSize: {
    xs: '0.75rem',
    sm: '0.875rem',
    base: '1rem',
    lg: '1.125rem',
    xl: '1.313rem',
    xl2: '1.438rem',
    xl3: '1.875rem',
    xl4: '1.938rem',
    xl5: '3rem',
    xl6: '4.25rem'
  },
  colors: {
    primary: '#2A52A9',
    darkBlue: '#0d3786',
    secondary: '#d0a617',
    menu: '#ffffff',
    dark: '#242934',
    gray: 'rgba(42, 82, 169, 0.04)',
    light: '#f7fafc',
    background: '#fff',
    text: '#7c8394',
    darkGray: '#50545d'
  },
  breakpoints: {
    mobile: 'only screen and (max-width: 50rem)',
    tablet: 'only screen and (max-width: 65rem)'
  },
  spacings: {
    xxSmall: '.25rem',
    xSmall: '.5rem',
    small: '1rem',
    medium: '2rem',
    large: '3rem',
    xLarge: '4rem',
    xxLarge: '6rem'
  },
  animations: {
    button: 'box-shadow 0.3s ease',
    link: 'color 0.2 ease'
  },
  shadows: {
    shadow1: '0px, 5px, 20px, rgba(30, 30, 31, 0.05)'
  }
}

export const GlobalStyle = createGlobalStyle`
  :root{
    font-size: 100%;
  }

  *, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    position: relative;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    line-height: 1.5;
    letter-spacing: 0;
    background-color: ${(props) => props.theme.colors.background};
    -webkit-font-smoothing: antialiased;
  }

  body, input, button, textarea {
    font-family: ${(props) => props.theme.fontFamily.main};
    font-size: ${(props) => props.theme.fontSize.small};
    color: ${(props) => props.theme.colors.dark};
  }

  h1, h2 {
    font-family: ${(props) => props.theme.fontFamily.heading};
    font-weight: bold;
  }
  h1 {
    font-size: ${(props) => props.theme.fontSize.xl6};
  }
  h2 {
    font-size: ${(props) => props.theme.fontSize.xl5};
  }
  h3, h4, h5 {
    font-family: ${(props) => props.theme.fontFamily.main};
    font-weight: bold;
  }
  h3 {
    font-size: ${(props) => props.theme.fontSize.xl4};
  }
  h4 {
    font-size: ${(props) => props.theme.fontSize.xl2};
  }
  h5 {
    font-size: ${(props) => props.theme.fontSize.xl};
  }

  a {
    font-weight: bold;
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }

  strong {
    font-weight: 600;
  }

  button {
    background: Transparent no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
  }
`
